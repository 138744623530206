section#post-intro {
  background-color: $light-purple;

  a.dm-btn {
    background-color: transparent;
    color: $third;
    
    span.fas {
      color: $third;
      margin-left: 0;
      margin-right: .8rem;
    }
  }
}

article.post-content {
  // Hide lines number if parameter "code_no_lines" is set to true on post
  &.no_lines {
    pre.highlight code .lineno {
      display: none;
    }
  }
  position: relative;
  font-family: Poppins, serif;
  // Pour autoriser les mots (notamment les liens) à revenir à la ligne
  word-wrap: break-word;

  p {
    text-align: justify;
  }

  // Police à pas fixe pour les blocs de code
  pre.highlight code {
    .lineno {
      margin-right: 0.75rem;
    }
    * {
      font-family: JetBrains Mono, monospace;
      line-height: 1.5rem;
    }
  }


  img {
    max-width: 75%;
    max-height: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  // légende sous images
  img+em {
    display: block;
    text-align: center;
  }

  h1 {
    margin-bottom: 1rem;
    &:not(:first-of-type) {
      margin-top: 2.5rem;
    }
    color: $primary;
    font-size: xx-large;
    font-family: 'Poppins', serif;
  }
  
  h2 {
    margin-bottom: 1rem;
    margin-top: 30px;
    font-size: x-large;
    font-family: 'Nunito', serif;
    color: $secondary;
  }
  
  h3 {
    margin-top: 20px;
    font-size: larger;
    font-family: 'Nunito', serif;
    color: $third;
  }

  blockquote {
    padding: 0.75rem;
    border-left: 5px $gray-200 solid;
    background: #f5f5f5;
    border-radius: 0.6rem;
    font-style: italic;
    color: $gray-600;
    p {
      margin-bottom: 0;
    }
  }

  // On style le sommaire (premier ul)
  & > ul:first-of-type {
    border: 1px solid $light-grey;
    border-radius: 8px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 1rem;

    li a {
      color: black;
    }
  }
  
  // En mode grand écran, on met le menu à gauche
  $margin: 20rem;
  @media (min-width: $md-size) {
    margin-left: $margin;

    & > ul:first-of-type {
      position: absolute;
      width: $margin;
      left: calc(-2rem - #{$margin});
      top: 0;
    }
  }

  // Trick: remonter un peu les anchor sinon pbm avec la sticky navbar
  a.anchor {
    position: relative;
    // taille de la navbar
    top: -4rem;
  }

  @media (min-width: 768px) {
    table:not(.rouge-table) {   
      th, td {
        padding: 0.5rem;
      }
      th {
        background-color: #ddd;
      }
      tr:nth-child(2n) td {
        background-color: #eee;
      }
    }
  }
  

  @media (max-width: 768px){
    table:not(.rouge-table) {
      thead {
        display: none;
      }
      tr {
        display: block;

        &:nth-child(2n) td {
          background-color: #eee;
        }
      }
      td {
        padding-left: 1rem;
        padding-right: 1rem;
        display: block;
        text-align: right;
        &:before {
          content: attr(data-label) ' :';
          float: left;
          font-weight: bold;
        }
        &:first-child {
          padding-top: 1rem;
        }
        &:last-child {
          padding-bottom: 1rem;
        }
      }
    }
  }
}
