@import "variables";
@import "_navbar";
@import "_footer";

// SCSS FOR EACH PAGE
@import "_index";
@import "_offre";
@import "_savoir-faire";
@import "_dev-machine";
@import "_contact";
@import "_blog";
@import "_recrutement";

// SCSS FOR BLOG
@import "code";
@import "_post";

// PLUGINS
@import "_accordion";

// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

a.dm-btn, button.dm-btn {
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: .5rem 1.5rem .4rem;
  
  // Pour les boutons avec chevrons
  span.fas {
    font-size: .9rem;
    color: $white;
    margin-left: .8rem;
  } 

  &.dm-btn-blue {
    color: $white;
    background-color: $secondary;
  }
  &.dm-btn-white {
    color: $secondary;
    background-color: transparent;
    border-color: $secondary;
  }
  &.dm-btn-orange {
    color: $white;
    background-color: $primary;
  }
}

// PADDING POUR CHAQUE BLOC
section {
  padding: 3rem 0;
  @media (max-width: $sm-size) {
    padding: 1rem 0;
  }

  // Intro de chaque page
  &.intro {
    margin-top: 0;
    div.container > div.row {
      align-items: center;
    }
    h1 {
      text-align: left;
    }
    p, li {
      text-align: justify;
    }
    img {
      max-height: 40vh;
    }
    div.btn-container {
      justify-content: start;
      margin: 0;
      gap: 1rem;
    }
    @media (max-width: $sm-size) {
      // quand l'image est dessous, petite marge
      div.right-img {
        margin-top: 2rem;
      }
      div.btn-container {
        justify-content: center;
      }
    }
  }
}

* {
  font-family: 'Nunito';
}

// TITRES
h1.section-heading,
h2.section-subheading {
  text-align: center;
  width: 100%;
  line-height: 120%;
  
  @media (max-width: $sm-size) {
    line-height: initial;
  }
}

h1.section-heading {
  color: $secondary;
  font-weight: 900;
  font-size: 3rem;
  margin: 1.5rem 0;
  font-family: 'Nunito Bold', 'Nunito';
  
  @media (max-width: $sm-size) {
    font-size: 2rem;
  }
}

h2.section-subheading {
  color: $third;
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 2rem;

  @media (max-width: $sm-size) {
    font-size: 1rem;
  }
}

// TEXT COLORS
.text-orange {
  color: $primary !important;
}
.text-blue {
  color: $secondary  !important;
}
.text-grey {
  color: $third !important;
}

// LISTES FLECHES CUSTOM (pour les ul + articles)
ul.arrow-list, article.post-content ul, article.post-content ol {
  // on retire le style
  list-style-type: none;
  & > ul {
    // on retire le style aussi si plusieurs étages
    list-style-type: none;
  }
  // on retire la marge de base de "ul"
  padding-inline-start: 0;

  li {
    background-image: url(/assets/images/circle-arrow-right.svg);
    background-repeat: no-repeat;
    line-height: 23px;
    padding-left: 30px;
    margin-bottom: .5rem;
    text-align: justify;
  }
}

.brittany {
  display: inline-block;
  margin-top: -0.2rem;
  height: 0.8rem;
  width: auto;
}
