div.dm-accordion {
  width: 100%;

  // 1 ensemble title + content
  div.accordion-container {
    padding: 1rem;
    border-bottom: 1px solid $light-grey;

    &:last-child {
      border-bottom: none;
    }
  }
  
  button.accordion-btn {
    padding: 0;
    padding: 1rem 0;
    border: none;
    background: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
   
    span.collapse-title {
      font-size: 2rem;
      font-weight: 700;
      color: $secondary;
    }

    span.fas {
      color: $primary;
      font-size: 1.5rem;
    }

    // Quand on ferme, on tourne le chevron
    &.collapsed {
      span.fas {
        transform: rotate(180deg);
      }
    }
  }
}