$blue-bg: rgba(106, 191, 252, 0.1);

section#contact-intro {
  background-color: $blue-bg;
  img {
    max-height: 30vh;
  }
}

section#contact-form {
  button {
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  div#success-send {
    display: none;
    width: 100%;
    background: rgba(106, 252, 138, .1);
    color: $secondary;
    height: 8rem;

    align-items: center;
    justify-content: center;
  }
}

section#contact-items {
  div.container {
    background-image: url("/assets/images/website/page-contact/items/bg.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 16px;
    padding: 3rem;

    div.contact-items-container {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 16px;
      padding: 5rem 3rem;

      @media (max-width: $sm-size) {
        flex-direction: column;
        justify-content: stretch;
        padding: 2rem 1rem;
        row-gap: 2rem;
      }

      div.contact-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        img {
          margin: 0 auto 2rem;
          max-width: 100%;
        }
        p {
          text-align: center;
          color: $secondary;
          margin: 0;
        }
        a {
          color: $secondary;
          text-decoration: underline;
          font-weight: 600;
        }

        // Mode phone, on inverse
        @media (max-width: $sm-size) {
          flex-direction: row;
          width: 100%;
          margin-top: 1rem;
          img {
            margin: 0;
            width: 10%;
            &.path {
              width: 20%;
            }
          }
          div.contact-data {
            width: 60%;
            margin-left: 20%;
            p { text-align: left }
          }
        }
      }
    }
  }
}