div.highlighter-rouge {
  background-color: $code-background;
  border-radius: $code-border-radius;
  padding: $code-padding;
  box-sizing: border-box;
  margin-bottom: 1rem;

  pre {
    color: $code-color;
    margin: 0;
  }
}

/* Inline code */
code {
  font-family: 'JetBrains Mono';
  font-size: 0.8rem;
}

.code-header {
  display: flex;
  justify-content: flex-end;
}

.copy-code-button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 4px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 4px 8px;
  background-color: $code-background;
  border-radius: $code-border-radius $code-border-radius 0 0;
  color: $code-color;
  border-bottom: 1px solid #666666;

  &::before {
    content: "Copier";
  }

  &::after {
    content: "📋";
    display: block;
  }

  // This class will be toggled via JavaScript
  &.copied {
      background-color: #dddddd;
      color: black;
    &::before {
      content: "Copié!";
    }

    &::after {
      content: "✔️";
    }
  }
}
