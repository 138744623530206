/* You can add global styles to this file, and also import other style files */
// Font Mixins
@mixin serif-font {
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@mixin script-font {
  font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@mixin body-font {
  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@mixin heading-font {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@font-face {
  font-family: Nunito;
  src: url(/assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
}
@font-face {
  font-family: Nunito Bold;
  src: url(/assets/fonts/Nunito/Nunito-Bold.ttf) format("truetype");
}
@font-face {
  font-family: Poppins;
  src: url(/assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: JetBrains Mono;
  src: url(/assets/fonts/JetBrains-Mono/JetBrainsMono-Regular.woff2) format("woff2");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: JetBrains Mono;
  src: url(/assets/fonts/JetBrains-Mono/JetBrainsMono-Bold.woff2) format("woff2");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: JetBrains Mono;
  src: url(/assets/fonts/JetBrains-Mono/JetBrainsMono-Italic.woff2) format("woff2");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: JetBrains Mono;
  src: url(/assets/fonts/JetBrains-Mono/JetBrainsMono-BoldItalic.woff2) format("woff2");
  font-style: italic;
  font-weight: bold;
}

// style go top button
.go-top-button{
  z-index: 20;
}
// Variables

// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;


/* kalixo*/
$primary: #fc766a !default; //D26D64
$secondary: #243252 !default;
$third: #69779B !default;

$light-blue: #98B7C4 !default;
$lighter-blue: #F4F6FA !default;
$light-grey: #C0C6D5 !default;

// BG
$light-pink: rgba(252, 118, 106, 0.1);
$light-purple: rgba(106, 121, 252, 0.1);

/* code block variables */
$code-background: #eeeeee;
$code-border-radius: 0.6rem;
$code-color: black;
$code-padding: 1rem;

// SIZE
$md-size: 991px;
$sm-size: 767px;
