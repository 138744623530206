@import "swing.scss";

// Styling for the navbar
#mainNav {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $white;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .1), 0 6px 10px 0 rgba(0, 0, 0, .07), 0 1px 18px 0 rgba(0, 0, 0, .06);
  // min-height pour avoir + grand pour le menu déroulant sur téléphone 
  min-height: 4rem;
  transition: box-shadow .2s, background-color .2s;

  // btn mode phone
  .navbar-toggler {
    font-size: 12px;
    right: 0;
    padding: .8rem;
    color: white;
    border: 0;
    background-color: $primary;
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 90%;
        font-weight: 400;
        padding: 0.75em 0;
        letter-spacing: 1px;
        color: $secondary;
        &.active,
        &:hover {
          color: $secondary;
          -webkit-animation: swing 1s ease;
          animation: swing 1s ease;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
        }
      }
    }
  }
}

// Mode grand écran
@media(min-width: $md-size) {
  #mainNav {
    padding: 1rem 0;
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 0 1rem !important;
        }
      }
    }
  }
}

// data-scroll ajouté grâce au script "scroll-listener.js"
html[data-scroll='0'] {
  // Pas d'ombre quand le scroll est en haut
  #mainNav {
    box-shadow: none;
  }
  
  // Style spécial sur la première page (sauf phone)
  @media (min-width: $md-size) {
    body.main-bg {
      #mainNav {
        background-color: transparent;
      }
    }
  }
}
