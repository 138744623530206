section#recrutement-intro {
  background-color: #FFF1F0;
  img {
    max-height: 35vh;
  }
}

section#recrutement-posts {
  div.recrutement-posts {
    display: flex;
    flex-wrap: wrap;

    div.recrutement-post {
      border: 1px solid $light-grey;
      box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      div.heading {
        border-bottom: 1px solid $light-grey;
        padding: 1rem;
        p.title {
          margin: 0;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      div.contract-data, span.description {
        padding: 1rem;
        padding-bottom: 0;
      }

      div.contract-data {
        column-gap: .6rem;

        span {
          display: flex;
          align-items: center;
          padding: .2rem .8rem;
          border-radius: 10px;
          font-weight: 400;
          height: 2rem;
  
          &.contract-cdi {
            background-color: #D1FAE5;
            color: #065F46;
          }
          
          &.contract-full-time {
            background-color: #F3F4F6;
            color: #4B5563;
          }

          &.contract-location {
            column-gap: 0.4rem;
            background-color: rgba(36, 50, 82, .1);
            color: $secondary;
            // pin
            img {
              height: 75%;
            }
          }
        }
      }


      div.empty-space {
        flex-grow: 2;
      }

      div.btn-container {
        text-align: right;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: end;
        column-gap: 1rem;

        a.postuler {
          display: flex;
          align-items: center;
          column-gap: 1rem;
          p {
            margin-bottom: 0;
          }
          // envelope
          img {
            width: 1.5rem;
          }
        }
        
        // Mode phone: boutons en 2 lignes
        @media (max-width: $sm-size) {
          flex-direction: column;
          row-gap: 1rem;

          a {
            width: 80%;
            justify-content: center;
          }
        }
      }
    }
  }
}
